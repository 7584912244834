{
  "lang_markread": "Alles gelesen",
  "lang_newest": "Neueste",
  "lang_unread": "Ungelesen",
  "lang_starred": "Favoriten",
  "lang_tags": "Markierungen",
  "lang_alltags": "Alle Markierungen",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_hours": "vor {0,plural,one {1 Stunde},other {# Stunden}}",
  "lang_minutes": "vor {0,plural,one {1 Minute},other {# Minuten}}",
  "lang_seconds": "{0,plural,one {Jetzt gerade},other {vor # Sekunden}}",
  "lang_days": "{0,plural,zero {heute},one {gestern},other {vor # Tagen}}",
  "lang_star": "behalten",
  "lang_unstar": "vergessen",
  "lang_mark": "als gelesen markieren",
  "lang_unmark": "als ungelesen markieren",
  "lang_load_img": "Zeige Bilder",
  "lang_open_window": "öffnen",
  "lang_next": "weiter",
  "lang_searchbutton": "suchen",
  "lang_refreshbutton": "Quellen aktualisieren",
  "lang_settingsbutton": "Einstellungen",
  "lang_logoutbutton": "Abmelden",
  "lang_loginbutton": "Anmelden",
  "lang_sources": "Quellen",
  "lang_source_add": "Quelle hinzufügen",
  "lang_source_opml": "oder OPML-Datei oder Google Reader importieren",
  "lang_source_export": "Quellen exportieren",
  "lang_source_edit": "bearbeiten",
  "lang_source_filter": "Filter",
  "lang_source_delete": "löschen",
  "lang_source_warn": "Wirklich diesen Feed entfernen?",
  "lang_source_new": "Neue ungespeicherte Quelle",
  "lang_source_title": "Titel",
  "lang_source_autotitle_hint": "Leer lassen um automatisch Titel zu setzen",
  "lang_source_tags": "Markierungen",
  "lang_source_pwd_placeholder": "Nicht geändert",
  "lang_source_comma": "Komma getrennt",
  "lang_source_select": "Quelle auswählen",
  "lang_source_type": "Typ",
  "lang_source_save": "speichern",
  "lang_source_cancel": "abbrechen",
  "lang_source_saved": "gespeichert",
  "lang_source_last_post": "Letzter gesehener Beitrag",
  "lang_no_entries": "keine Einträge gefunden",
  "lang_more": "mehr",
  "lang_login": "Anmelden",
  "lang_login_username": "Benutzername",
  "lang_login_password": "Passwort",
  "lang_no_title": "kein Titel",
  "lang_error": "Ein Fehler ist aufgetreten",
  "lang_filter": "Filter",
  "lang_online_count": "Auf dem Server verfügbare Elemente",
  "lang_offline_count": "Lokal verfügbare Artikel",
  "lang_searchremove": "Suche ausblenden",
  "lang_source_refresh": "Diese Quelle aktualisieren",
  "lang_login_invalid_credentials": "Falscher Benutzername/Passwort",
  "lang_login_offline": "Offline-Speicherung",
  "lang_streamerror": "Keine Artikel geladen, neu laden?",
  "lang_reload_list": "Liste neu laden",
  "lang_sources_refreshed": "Quellen aktualisiert",
  "lang_app_update": "selfoss wurde aktualisiert, bitte neu laden",
  "lang_app_reload": "Neu laden",
  "lang_error_session_expired": "Session abgelaufen",
  "lang_error_add_source": "Die Quelle konnte nicht hinzugefügt werden:",
  "lang_error_delete_source": "Die Quelle konnte nicht gelöscht werden:",
  "lang_error_load_tags": "Konnte Markierungen nicht laden:",
  "lang_error_unknown_tag": "Unbekannte Markierung:",
  "lang_error_unknown_source": "Unbekannte Quell-ID:",
  "lang_error_mark_items": "Es können nicht alle sichtbaren Elemente markiert werden:",
  "lang_error_mark_item": "Das Markieren/Deaktivieren von Elementen ist nicht möglich:",
  "lang_error_star_item": "Artikel kann nicht ge-sternt oder ent-sternt werden:",
  "lang_error_logout": "Konnte nicht ausloggen:",
  "lang_error_loading": "Ladefehler:",
  "lang_error_loading_stats": "Nav-Statistiken können nicht geladen werden:",
  "lang_error_saving_color": "Neue Farbe kann nicht gespeichert werden:",
  "lang_error_refreshing_source": "Quellen können nicht aktualisiert werden:",
  "lang_error_sync": "Letzte Änderungen vom Server konnten nicht synchronisiert werden:",
  "lang_error_offline_storage": "Offline-Speicherfehler: {0}. Neu laden könnte helfen. Deaktiviere offline vorerst.",
  "lang_error_invalid_subsection": "Ungültiger Unterabschnitt:",
  "lang_search_label": "Suchbegriff",
  "lang_article_actions": "Aktionen",
  "lang_error_configuration": "Nicht möglich die Konfiguration zu laden.",
  "lang_info_url_copied": "URL in die Zwischenablage kopiert.",
  "lang_share_native_label": "teilen",
  "lang_share_diaspora_label": "auf Diaspora teilen",
  "lang_share_twitter_label": "auf Twitter teilen",
  "lang_share_facebook_label": "auf Facebook teilen",
  "lang_share_mastodon_label": "auf Mastodon teilen",
  "lang_share_pocket_label": "in Pocket speichern",
  "lang_share_wallabag_label": "in Wallabag speichern",
  "lang_share_wordpress_label": "teile auf WordPress",
  "lang_share_mail_label": "per E-Mail teilen",
  "lang_share_copy_label": "Link in die Zwischenablage kopieren",
  "lang_error_share_native_abort": "Es ist nicht möglich das Objekt zu teilen – entweder gibt es keine Ziele zum Teilen oder die Aktion wurde abgebrochen.",
  "lang_error_share_native": "Es ist nicht möglich das Objekt zu teilen.",
  "lang_close_entry": "Schließen",
  "lang_error_offline_storage_not_available": "Offline-Speicherung ist nicht verfügbar. Stelle sicher, dass dein Browser die {0}IndexedDB-API unterstützt{1} und, falls du einen Browser auf Grundlage von Google Chrome benutzt, dass du selfoss nicht im Incognito-Modus ausführst.",
  "lang_error_edit_source": "Konnte die Änderungen an den Quellen nicht speichern:",
  "lang_error_unwritable_logs": "Logdateien konnten nicht geschrieben werden.",
  "lang_error_check_system_logs": "Ein Fehler ist aufgetreten, bitte die Systemlogs prüfen.",
  "lang_error_check_log_file": "Es ist ein Fehler aufgetreten, bitte Protokolldatei „{0}“ prüfen.",
  "lang_offline_navigation_unavailable": "Markierungen und Quellen zu wechseln wird derzeit im Offline-Modus nicht unterstützt."
}

{
  "lang_markread": "既読にする",
  "lang_filter": "フィルター",
  "lang_newest": "新着",
  "lang_unread": "未読",
  "lang_starred": "スター",
  "lang_tags": "タグ",
  "lang_alltags": "全てのタグ",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_hours": "{0,plural,one {1 時間},other {# 時間}}前",
  "lang_minutes": "{0,plural,one {1 分},other {# 分}}前",
  "lang_seconds": "{0,plural,one {1 秒},other {# 秒}}前",
  "lang_days": "{0,plural,zero {今日},one {昨日},other {# 日前}}",
  "lang_star": "スターをつける",
  "lang_unstar": "スターを外す",
  "lang_mark": "既読にする",
  "lang_unmark": "未読にする",
  "lang_load_img": "画像を表示",
  "lang_open_window": "開く",
  "lang_next": "次へ",
  "lang_searchbutton": "検索",
  "lang_searchremove": "検索を非表示",
  "lang_refreshbutton": "ソースの更新",
  "lang_settingsbutton": "設定",
  "lang_logoutbutton": "ログアウト",
  "lang_loginbutton": "ログイン",
  "lang_sources": "ソース",
  "lang_source_add": "ソースを追加",
  "lang_source_opml": "または OPML ファイルをインポート",
  "lang_source_export": "ソースをエクスポート",
  "lang_source_edit": "編集",
  "lang_source_filter": "フィルター",
  "lang_source_delete": "削除",
  "lang_source_warn": "このソースを削除しますか？",
  "lang_source_new": "新しい未保存のソース",
  "lang_source_title": "タイトル",
  "lang_source_tags": "タグ",
  "lang_source_pwd_placeholder": "変更されていません",
  "lang_source_comma": "カンマ(,)区切り",
  "lang_source_select": "ソースを選択してください",
  "lang_source_type": "タイプ",
  "lang_source_save": "保存",
  "lang_source_cancel": "キャンセル",
  "lang_source_saved": "保存されました",
  "lang_source_last_post": "最終参照日",
  "lang_source_refresh": "このソースを更新",
  "lang_no_entries": "記事がありません",
  "lang_more": "もっと見る",
  "lang_login": "ログイン",
  "lang_login_username": "ユーザー名",
  "lang_login_password": "パスワード",
  "lang_no_title": "タイトルなし",
  "lang_error": "エラーが発生しました",
  "lang_streamerror": "記事を読み込めませんでした。再読み込みしますか？",
  "lang_source_autotitle_hint": "空のままにするとタイトルを取得します",
  "lang_login_invalid_credentials": "ユーザー名/パスワードが間違っています",
  "lang_reload_list": "一覧を再読み込み",
  "lang_sources_refreshed": "ソースを更新しました",
  "lang_error_session_expired": "セッションが期限切れです",
  "lang_error_add_source": "ソースを追加できません:",
  "lang_error_delete_source": "ソースを削除できません:",
  "lang_error_load_tags": "タグを読み込みできません:",
  "lang_error_unknown_tag": "不明なタグ:",
  "lang_error_unknown_source": "不明なソースID:",
  "lang_error_mark_items": "すべての表示項目をマークできません:",
  "lang_error_mark_item": "項目をマーク/マーク解除できません:",
  "lang_error_star_item": "項目をスター/スター解除できません:",
  "lang_error_logout": "ログアウトできません:",
  "lang_error_loading": "読み込みエラー:",
  "lang_error_loading_stats": "NAV統計を読み込みできません:",
  "lang_error_saving_color": "新しい色を保存できません:",
  "lang_error_refreshing_source": "ソースを更新できません:",
  "lang_error_sync": "サーバから最後の変更を同期できませんでした:",
  "lang_error_invalid_subsection": "不正なサブセクション:",
  "lang_online_count": "サーバーで利用可能な項目",
  "lang_offline_count": "ローカルで利用可能な項目",
  "lang_login_offline": "オフライン ストレージ",
  "lang_app_update": "selfoss が更新されました。再読み込みしてください",
  "lang_app_reload": "再読み込み",
  "lang_error_offline_storage": "オフライン ストレージ エラー: {0}。 再読み込みが役立つことがあります。今のところ、オフラインを無効にします。",
  "lang_search_label": "検索条件",
  "lang_article_actions": "アクション",
  "lang_error_configuration": "設定を読み込めません。",
  "lang_info_url_copied": "URLをクリップボードにコピーしました。",
  "lang_article_reading_time": "{0} 分 読みました",
  "lang_source_menu": "その他のアクション",
  "lang_source_browse": "検索",
  "lang_source_warn_cancel_dirty": "保存されていない変更を破棄しますか？",
  "lang_sources_leaving_unsaved_prompt": "ソースの変更を保存せずに設定を残しますか？",
  "lang_experimental": "試験的",
  "lang_error_check_system_logs": "エラーが発生しました。システムログを確認してください。",
  "lang_error_edit_source": "ソースの変更を保存できませんでした：",
  "lang_error_share_native": "アイテムを共有できません。",
  "lang_error_share_native_abort": "アイテムを共有できません—共有先がないか、キャンセルしました。",
  "lang_share_pocket_label": "Pocket に保存",
  "lang_share_twitter_label": "Twitter でシェア",
  "lang_close_entry": "閉じる",
  "lang_share_facebook_label": "Facebook でシェア",
  "lang_offline_navigation_unavailable": "現在、オフラインモードではタグとソースの切り替えができません。",
  "lang_share_diaspora_label": "Diaspora でシェア",
  "lang_share_wallabag_label": "Wallabag に保存",
  "lang_share_copy_label": "リンクをクリップボードにコピー",
  "lang_error_check_log_file": "エラーが発生しました。ログファイル「{0}」を確認してください。",
  "lang_error_unwritable_logs": "ログを書き込めません。",
  "lang_share_mail_label": "メールを使用して共有",
  "lang_share_native_label": "共有",
  "lang_share_wordpress_label": "WordPress でシェア",
  "lang_error_offline_storage_not_available": "オフラインストレージは利用できません。 Webブラウザ {0} がIndexedDBAPI {1} をサポートしていること、および Google Chrome ベースのブラウザを使用している場合は、シークレットモードで selfoss を実行していないことを確認してください。",
  "lang_share_mastodon_label": "Mastodon でシェア"
}

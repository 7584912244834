import { useMemo, useState, useEffect } from 'react';

/**
 * useMediaMatch
 *
 * A react hook that signals whether or not a media query is matched.
 *
 * @param query The media query to signal on. Example, `"print"` will signal
 * `true` when previewing in print mode, and `false` otherwise.
 * @returns Whether or not the media query is currently matched.
 * @see https://rooks.vercel.app/docs/useMediaMatch
 */
function useMediaMatch(query) {
    const matchMedia = useMemo(() => window.matchMedia(query), [query]);
    const [matches, setMatches] = useState(() => matchMedia.matches);
    useEffect(() => {
        setMatches(matchMedia.matches);
        const listener = (event) => setMatches(event.matches);
        if (matchMedia.addEventListener) {
            matchMedia.addEventListener("change", listener);
            return () => matchMedia.removeEventListener("change", listener);
        }
        else {
            matchMedia.addListener(listener);
            return () => matchMedia.removeListener(listener);
        }
    }, [matchMedia]);
    if (typeof window === "undefined") {
        console.warn("useMediaMatch cannot function as window is undefined.");
        return false;
    }
    return matches;
}

export { useMediaMatch };

{
  "lang_markread": "позначити як прочитані",
  "lang_newest": "нові",
  "lang_unread": "не прочитані",
  "lang_starred": "відзначені",
  "lang_tags": "Теги",
  "lang_alltags": "усі теги",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_hours": "{0,plural,one {1 годину},other {# години}} тому",
  "lang_minutes": "{0,plural,one {1 хвилину},other {# хвилини}} тому",
  "lang_seconds": "{0,plural,one {1 секунду},other {# секунди}} тому",
  "lang_star": "відзначити",
  "lang_unstar": "зняти позначку",
  "lang_mark": "прочитане",
  "lang_unmark": "не прочитане",
  "lang_load_img": "завантажити зображення",
  "lang_open_window": "відкрити",
  "lang_searchbutton": "пошук",
  "lang_sources": "Джерела",
  "lang_source_add": "додати джерело",
  "lang_source_opml": "або імпортувати з OPML файлу",
  "lang_source_export": "експорт джерел",
  "lang_source_edit": "редагувати",
  "lang_source_delete": "видалити",
  "lang_source_warn": "дійсно видалити це джерело?",
  "lang_source_new": "нове не збережене джерело",
  "lang_source_title": "Назва",
  "lang_source_tags": "Теги",
  "lang_source_comma": "розілені комою",
  "lang_source_select": "виберіть тип джерела",
  "lang_source_type": "Тип",
  "lang_source_save": "зберегти",
  "lang_source_cancel": "Скасувати",
  "lang_source_saved": "збережено",
  "lang_no_entries": "не знайдено записів",
  "lang_more": "ще",
  "lang_login": "вхід",
  "lang_login_username": "ім'я",
  "lang_login_password": "пароль",
  "lang_no_title": "без назви",
  "lang_filter": "Фільтр",
  "lang_online_count": "Доступні елементи на сервері",
  "lang_offline_count": "Елементи, доступні локально",
  "lang_source_browse": "Перегляд",
  "lang_share_facebook_label": "Поділитися на Facebook",
  "lang_error_check_log_file": "Виникла помилка, будь ласка, перевірте файл журналу \"{0}\".",
  "lang_error_check_system_logs": "Виникла помилка, будь ласка, перевірте системні журнали.",
  "lang_error_unwritable_logs": "Неможливо писати логи.",
  "lang_streamerror": "Елементи не завантажені, перезавантажити?",
  "lang_error_edit_source": "Не вдалося зберегти зміни джерела:",
  "lang_error_mark_items": "Не вдається позначити всі видимі елементи:",
  "lang_error_star_item": "Неможливо позначити або зняти зірочку:",
  "lang_error_logout": "Не вдалося вийти:",
  "lang_error_saving_color": "Не вдається зберегти новий колір:",
  "lang_error_sync": "Не вдалося синхронізувати останні зміни з сервера:",
  "lang_error_unknown_source": "Невідомий ідентифікатор джерела:",
  "lang_error_load_tags": "Не вдалося завантажити теги:",
  "lang_error_unknown_tag": "Невідомий тег:",
  "lang_error_refreshing_source": "Не вдається оновити джерела:",
  "lang_error_mark_item": "Неможливо позначити/зняти відмітку з елемента:",
  "lang_error_offline_storage": "Помилка в автономному сховищі: {0}. Перезавантаження може допомогти. Наразі відключаємо офлайн.",
  "lang_days": "{0,plural,zero {сьогодні},one {вчора},other {# днів тому}}",
  "lang_error_unauthorized": "Будь ласка, {link_begin}увійдіть в систему{link_end}, щоб продовжити.",
  "lang_share_native_label": "Поділитися",
  "lang_share_twitter_label": "Поділитися в Twitter",
  "lang_share_mastodon_label": "Поділитися на Mastodon",
  "lang_source_deleting": "Видалення джерела…",
  "lang_share_wordpress_label": "Поділитися на WordPress",
  "lang_share_copy_label": "Скопіювати посилання в буфер обміну",
  "lang_search_label": "Пошуковий запит",
  "lang_share_mail_label": "Поділіться на e-mail",
  "lang_searchremove": "Приховати пошук",
  "lang_logoutbutton": "Вийти",
  "lang_settingsbutton": "Налаштування",
  "lang_loginbutton": "Увійти",
  "lang_no_sources": "Джерела не налаштовані.",
  "lang_source_filter": "Фільтр",
  "lang_error_loading_stats": "Не вдається завантажити навігаційну статистику:",
  "lang_source_menu": "Додаткові дії",
  "lang_sources_leaving_unsaved_prompt": "Покинути налаштування з незбереженими змінами джерела?",
  "lang_login_error_generic": "Не вдалося увійти: {errorMessage}",
  "lang_experimental": "Експериментальні",
  "lang_tag_change_color_button_title": "Змінити колір",
  "lang_login_in_progress": "Спроба увійти…",
  "lang_sources_refreshed": "Джерела оновлені",
  "lang_error": "Виникла помилка",
  "lang_sources_loading": "Завантаження джерел…",
  "lang_login_invalid_credentials": "Неправильне ім'я користувача/пароль",
  "lang_error_loading": "Виникла помилка при завантаженні:",
  "lang_source_last_post": "Останній побачений пост",
  "lang_refreshbutton": "Оновити джерела",
  "lang_share_diaspora_label": "Поділитися на Diaspora",
  "lang_share_wallabag_label": "Зберегти у Wallabag",
  "lang_next": "Наступний",
  "lang_source_saving": "Збереження змін джерела…",
  "lang_entries_loading": "Завантаження записів…",
  "lang_reload_list": "Оновити список",
  "lang_share_pocket_label": "Зберегти в Pocket",
  "lang_info_url_copied": "URL скопійовано в буфер обміну.",
  "lang_offline_navigation_unavailable": "Перемикання тегів і джерел наразі недоступне в автономному режимі.",
  "lang_source_warn_cancel_dirty": "Скасувати незбережені зміни?",
  "lang_error_offline_storage_not_available": "Автономне сховище недоступне. Переконайтеся, що ваш веб-браузер {0} підтримує API IndexedDB{1} і, якщо ви використовуєте браузер на базі Google Chrome, то ви не застосовуєте selfoss в режимі інкогніто.",
  "lang_error_invalid_subsection": "Невірний підрозділ:",
  "lang_error_configuration": "Не вдається завантажити конфігурацію.",
  "lang_error_share_native_abort": "Неможливо поділитися - або немає цілей, або ви їх скасували.",
  "lang_error_share_native": "Неможливо поділитися.",
  "lang_close_entry": "Закрити",
  "lang_article_actions": "Дії",
  "lang_article_reading_time": "{0} хв читання",
  "lang_source_autotitle_hint": "Залиште порожнім, щоб отримати назву",
  "lang_source_pwd_placeholder": "Без змін",
  "lang_source_params_loading": "Завантажуємо параметри…",
  "lang_source_refresh": "Оновити це джерело",
  "lang_source_go_to_settings": "Відкрити в налаштуваннях",
  "lang_login_offline": "Автономне сховище",
  "lang_app_update": "selfoss оновлено, будь ласка, перезавантажте",
  "lang_app_reload": "Перезавантажити",
  "lang_error_session_expired": "Сесія вже закінчилася",
  "lang_error_add_source": "Не вдалося додати джерело:",
  "lang_error_delete_source": "Не вдалося видалити джерело:"
}

{
  "lang_markread": "Mindet olvastam",
  "lang_newest": "Legfrissebb",
  "lang_unread": "Olvasatlan",
  "lang_starred": "Megjelölt",
  "lang_tags": "Címkék",
  "lang_alltags": "Összes címke",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_hours": "{0,plural,one {1 órája},other {# órája}}",
  "lang_minutes": "{0,plural,one {1 perce},other {# perce}}",
  "lang_seconds": "{0,plural,one {1 másodperce},other {# másodperce}}",
  "lang_star": "Megjelölt",
  "lang_unstar": "Jelöletlen",
  "lang_mark": "Olvasott",
  "lang_unmark": "Olvasatlan",
  "lang_load_img": "Képbetöltés",
  "lang_open_window": "Megnyitás",
  "lang_searchbutton": "Keresés",
  "lang_sources": "Hírforrások",
  "lang_source_add": "Új hírforrás",
  "lang_source_opml": "OPML-fájl importálása",
  "lang_source_export": "Hírforrások exportálása",
  "lang_source_edit": "Módosítás",
  "lang_source_delete": "Törlés",
  "lang_source_warn": "Biztos törölhető a hírforrás?",
  "lang_source_new": "Új hírforrás",
  "lang_source_title": "Cím",
  "lang_source_tags": "Címkék",
  "lang_source_comma": "(vesszővel tagolva)",
  "lang_source_select": "A típus kiválasztása",
  "lang_source_type": "Típus",
  "lang_source_save": "Mentés",
  "lang_source_cancel": "Mégsem",
  "lang_source_saved": "Mentve",
  "lang_no_entries": "Nincs megjeleníthető hír.",
  "lang_more": "Továbbiak",
  "lang_login": "Bejelentkezés",
  "lang_login_username": "Felhasználónév",
  "lang_login_password": "Jelszó",
  "lang_no_title": "Nincs cím",
  "lang_filter": "Szűrő",
  "lang_online_count": "A kiszolgálón lévő hírek száma",
  "lang_offline_count": "A helyben tárolt hírek száma",
  "lang_error_session_expired": "A munkamenet lejárt.",
  "lang_app_reload": "Újratöltés",
  "lang_sources_refreshed": "Az új hírek keresése kész.",
  "lang_reload_list": "Újratöltés",
  "lang_error_unwritable_logs": "A napló nem írható.",
  "lang_error": "Hiba történt",
  "lang_source_pwd_placeholder": "Változatlan",
  "lang_source_filter": "Szűrő",
  "lang_loginbutton": "Bejelentkezés",
  "lang_logoutbutton": "Kijelentkezés",
  "lang_close_entry": "Bezárás",
  "lang_share_native_label": "Megosztás",
  "lang_error_load_tags": "Nem sikerült betölteni a címkéket:",
  "lang_searchremove": "Keresőpanel elrejtése",
  "lang_next": "Következő",
  "lang_app_update": "A selfoss frissítve lett, újra kell tölteni az oldalt.",
  "lang_error_offline_storage": "Tárolóhiba kapcsolat nélküli módban: {0}. Az újratöltés segíthet. A kapcsolat nélküli mód mostantól ki van kapcsolva.",
  "lang_error_star_item": "Nem sikerült módosítani a hír megjelöltségét:",
  "lang_days": "{0,plural,zero {ma},one {tegnap},other {# napja}}",
  "lang_share_twitter_label": "Twitter-megosztás",
  "lang_share_mail_label": "Megosztás e-mailben",
  "lang_settingsbutton": "Beállítások",
  "lang_sources_loading": "Hírforrások betöltése…",
  "lang_tag_change_color_button_title": "Szín cseréje",
  "lang_entries_loading": "Betöltés…",
  "lang_login_invalid_credentials": "Hibás felhasználónév vagy jelszó",
  "lang_login_error_generic": "Sikertelen bejelentkezés: {errorMessage}",
  "lang_login_offline": "Kapcsolat nélküli tároló",
  "lang_experimental": "Kísérleti funkció",
  "lang_error_saving_color": "Nem sikerült menteni az új színt:",
  "lang_error_sync": "Nem sikerült szinkronizálni a kiszolgálóval:",
  "lang_error_configuration": "Nem sikerült beolvasni a beállításokat.",
  "lang_error_share_native_abort": "Nem sikerült megosztani a hírt – vagy nincsenek megosztási célok, vagy a művelet törölve lett.",
  "lang_error_refreshing_source": "Nem sikerült új híreket keresni:",
  "lang_info_url_copied": "Az URL a vágólapra lett másolva.",
  "lang_article_actions": "Műveletek",
  "lang_article_reading_time": "~{0} perc",
  "lang_share_diaspora_label": "Diaspora-megosztás",
  "lang_share_facebook_label": "Facebook-megosztás",
  "lang_offline_navigation_unavailable": "A címkék és hírforrások módosítása kapcsolat nélküli módban jelenleg nem támogatott.",
  "lang_source_last_post": "Legutóbbi hír",
  "lang_source_refresh": "Új hírek keresése",
  "lang_source_go_to_settings": "Beállítások",
  "lang_sources_leaving_unsaved_prompt": "Nem minden változás lett elmentve. Biztos bezárható beállítófelület?",
  "lang_error_share_native": "Nem sikerült megosztani a hírt.",
  "lang_share_mastodon_label": "Mastodon-megosztás",
  "lang_share_pocket_label": "Pocket-mentés",
  "lang_share_wallabag_label": "Wallabag-mentés",
  "lang_share_wordpress_label": "WordPress-megosztás",
  "lang_share_copy_label": "Hivatkozás másolása",
  "lang_search_label": "Keresőkifejezés",
  "lang_refreshbutton": "Új hírek keresése",
  "lang_no_sources": "Nincsenek megadva hírforrások.",
  "lang_source_menu": "További műveletek",
  "lang_source_browse": "Megtekintés",
  "lang_source_deleting": "A hírforrás törlése…",
  "lang_source_warn_cancel_dirty": "Bezárás mentés nélkül?",
  "lang_source_autotitle_hint": "Üresen hagyva automatikus kitöltés",
  "lang_source_params_loading": "Paraméterek betöltése…",
  "lang_source_saving": "A változások mentése…",
  "lang_login_in_progress": "Bejelentkezés…",
  "lang_error_unauthorized": "A folytatáshoz {link_begin}bejelentkezés{link_end} szükséges.",
  "lang_error_check_log_file": "Hiba történt, részletek a naplófájlban: „{0}”.",
  "lang_error_check_system_logs": "Hiba történt, részletek a rendszernaplóban.",
  "lang_streamerror": "Nem töltődött le hír. Újratöltés?",
  "lang_error_add_source": "Nem sikerült hozzáadni a hírforrást:",
  "lang_error_edit_source": "Nem sikerült menteni a hírforrás változásait:",
  "lang_error_delete_source": "Nem sikerült törölni a hírforrást:",
  "lang_error_mark_items": "Nem sikerült olvasottra állítani a megjelenített híreket:",
  "lang_error_unknown_tag": "Ismeretlen címke:",
  "lang_error_unknown_source": "Ismeretlen hírforrás-azonosító:",
  "lang_error_mark_item": "Nem sikerült módosítani a hír olvasottságát:",
  "lang_error_logout": "Nem sikerült kijelentkezni:",
  "lang_error_loading": "Betöltési hiba:",
  "lang_error_loading_stats": "Nem sikerült betölteni a böngészési statisztikát:",
  "lang_error_offline_storage_not_available": "Nem áll rendelkezésre tároló a kapcsolat nélküli módhoz. Ellenőrizni kell, hogy a böngésző támogatja-e az {0}IndexedDB API{1}-t, illetve Google Chrome alapú böngészőben a selfoss nem használható inkognitó módban.",
  "lang_error_invalid_subsection": "Érvénytelen aloldal:"
}

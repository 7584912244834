{
  "lang_markread": "Okundu olarak işaretle",
  "lang_newest": "En yeni",
  "lang_unread": "Okunmayan",
  "lang_starred": "Yıldızlanan",
  "lang_tags": "Etiketler",
  "lang_alltags": "Tüm etiketler",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_hours": "{0, plural,other {#}} saat önce",
  "lang_minutes": "{0, plural,other {#}} dakika önce",
  "lang_seconds": "{0, plural,other {#}} saniye önce",
  "lang_star": "Yıldız ekle",
  "lang_unstar": "Yıldız kaldır",
  "lang_mark": "Okundu olarak işaretle",
  "lang_unmark": "Okunmadı olarak işaretle",
  "lang_load_img": "Resimleri göster",
  "lang_open_window": "Aç",
  "lang_searchbutton": "Ara",
  "lang_sources": "Kaynaklar",
  "lang_source_add": "Kaynak ekle",
  "lang_source_opml": "veya bir OPML dosyasını içe aktar",
  "lang_source_export": "Kaynakları dışa aktar",
  "lang_source_edit": "Düzenle",
  "lang_source_delete": "Sil",
  "lang_source_warn": "Bu kaynak gerçekten silinsin mi?",
  "lang_source_new": "Yeni kaydedilmemiş kaynak",
  "lang_source_title": "Başlık",
  "lang_source_tags": "Etiketler",
  "lang_source_comma": "Virgülle ayrılmış",
  "lang_source_select": "Lütfen kaynak seçin",
  "lang_source_type": "Tür",
  "lang_source_save": "Kaydet",
  "lang_source_cancel": "İptal",
  "lang_source_saved": "Kaydedildi",
  "lang_no_entries": "Yayın bulunmadı",
  "lang_more": "Daha fazla",
  "lang_login": "Oturum aç",
  "lang_login_username": "Kullanıcı adı",
  "lang_login_password": "Parola",
  "lang_no_title": "Başlık yok",
  "lang_article_actions": "Eylemler",
  "lang_close_entry": "Kapat",
  "lang_info_url_copied": "URL panoya kopyalandı.",
  "lang_error_share_native": "Öge paylaşılamıyor.",
  "lang_error_share_native_abort": "Öge paylaşılamıyor — ya hiç paylaşım hedefi yok ya da siz iptal ettiniz.",
  "lang_error_configuration": "Yapılandırma yüklenemiyor.",
  "lang_error_invalid_subsection": "Geçersiz alt bölüm:",
  "lang_error_offline_storage_not_available": "Çevrim dışı depolama kullanılamıyor. Web tarayıcınızın {0}IndexedDB API'sini{1} desteklediğinden ve Google Chrome tabanlı bir tarayıcı kullanıyorsanız selfoss'u gizli modda çalıştırmadığınızdan emin olun.",
  "lang_error_offline_storage": "Çevrim dışı depolama hatası: {0}. Yeniden yüklemek yardımcı olabilir. Çevrim dışı şimdilik devre dışı bırakılıyor.",
  "lang_error_sync": "Sunucudan son değişiklikler eşzamanlanamadı:",
  "lang_error_refreshing_source": "Kaynaklar yenilenemiyor:",
  "lang_error_saving_color": "Yeni renk kaydedilemiyor:",
  "lang_error_loading_stats": "Göz atma istatistikleri yüklenemiyor:",
  "lang_error_loading": "Yükleme hatası:",
  "lang_error_logout": "Oturum kapatılamadı:",
  "lang_error_star_item": "Ögeye yıldız eklenemiyor/yıldızı kaldırılamıyor:",
  "lang_error_mark_item": "Öge işaretlenemiyor/işareti kaldırılamıyor:",
  "lang_error_mark_items": "Tüm görünür ögeler işaretlenemiyor:",
  "lang_error_unknown_source": "Bilinmeyen kaynak kimliği:",
  "lang_error_unknown_tag": "Bilinmeyen etiket:",
  "lang_error_load_tags": "Etiketler yüklenemedi:",
  "lang_error_delete_source": "Kaynak silinemedi:",
  "lang_error_edit_source": "Kaynak değişiklikleri kaydedilemedi:",
  "lang_error_add_source": "Kaynak eklenemedi:",
  "lang_error_session_expired": "Oturumun süresi doldu",
  "lang_app_reload": "Yeniden yükle",
  "lang_app_update": "selfoss güncellendi, lütfen yeniden yükleyin",
  "lang_sources_refreshed": "Kaynaklar yenilendi",
  "lang_reload_list": "Listeyi yeniden yükle",
  "lang_streamerror": "Hiçbir öge yüklenmedi, yeniden yüklensin mi?",
  "lang_error_unwritable_logs": "Günlük yazılamıyor.",
  "lang_error_check_system_logs": "Bir hata oluştu, lütfen sistem günlüklerinize bakın.",
  "lang_error_check_log_file": "Bir hata oluştu, lütfen günlük dosyasına bakın “{0}”.",
  "lang_error": "Bir hata oluştu",
  "lang_login_offline": "Çevrim dışı depolama",
  "lang_login_invalid_credentials": "Yanlış kullanıcı adı/parola",
  "lang_source_refresh": "Bu kaynağı yenile",
  "lang_source_last_post": "Görülen son gönderi",
  "lang_source_pwd_placeholder": "Değişmedi",
  "lang_source_autotitle_hint": "Başlığı almak için boş bırakın",
  "lang_source_filter": "Filtrele",
  "lang_loginbutton": "Oturum aç",
  "lang_logoutbutton": "Oturumu kapat",
  "lang_settingsbutton": "Ayarlar",
  "lang_refreshbutton": "Kaynakları yenile",
  "lang_searchremove": "Aramayı gizle",
  "lang_search_label": "Arama terimi",
  "lang_share_copy_label": "Bağlantıyı panoya kopyala",
  "lang_share_mail_label": "E-posta ile paylaş",
  "lang_share_wordpress_label": "WordPress'te paylaş",
  "lang_share_wallabag_label": "Wallabag'e kaydet",
  "lang_share_pocket_label": "Pocket'a kaydet",
  "lang_share_facebook_label": "Facebook'ta paylaş",
  "lang_share_twitter_label": "Twitter'da paylaş",
  "lang_share_diaspora_label": "Diaspora'da paylaş",
  "lang_share_native_label": "Paylaş",
  "lang_next": "Sonraki",
  "lang_days": "{0,plural,zero {bugün},one {dün},other {# gün önce}}",
  "lang_offline_navigation_unavailable": "Etiketleri ve kaynakları değiştirmek şu anda çevrim dışı modda kullanılamıyor.",
  "lang_offline_count": "Yerel olarak kullanılabilen ögeler",
  "lang_online_count": "Sunucuda bulunan ögeler",
  "lang_filter": "Filtrele",
  "lang_article_reading_time": "{0} dakika okuma",
  "lang_experimental": "Deneysel",
  "lang_source_warn_cancel_dirty": "Kaydedilmemiş değişiklikler atılsın mı?",
  "lang_sources_leaving_unsaved_prompt": "Kaydedilmemiş kaynak değişiklikleri olmasına rağmen ayarlardan çıkılsın mı?",
  "lang_source_browse": "Göz at",
  "lang_source_menu": "Daha fazla eylem",
  "lang_tag_change_color_button_title": "Rengi değiştir",
  "lang_source_go_to_settings": "Ayarlarda aç",
  "lang_sources_loading": "Kaynaklar yükleniyor…",
  "lang_no_sources": "Yapılandırılan kaynak yok.",
  "lang_entries_loading": "Yayınlar yükleniyor…",
  "lang_source_deleting": "Kaynak siliniyor…",
  "lang_login_in_progress": "Oturum açmaya çalışılıyor…",
  "lang_source_params_loading": "Parametreler yükleniyor…",
  "lang_source_saving": "Kaynak değişiklikleri kaydediliyor…",
  "lang_error_unauthorized": "Devam etmek için lütfen {link_begin}oturum açın{link_end}.",
  "lang_login_error_generic": "Oturum açılamadı: {errorMessage}",
  "lang_share_mastodon_label": "Mastodon'da paylaş"
}

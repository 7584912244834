{
  "lang_markread": "marcar como leído",
  "lang_filter": "filtro",
  "lang_newest": "nuevos",
  "lang_unread": "no leídos",
  "lang_starred": "preferidos",
  "lang_online_count": "Elementos disponibles en el servidor",
  "lang_offline_count": "Elementos disponibles localmente",
  "lang_tags": "Etiquetas",
  "lang_alltags": "todas las etiquetas",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_hours": "hace {0,plural,one {1 hora},other {# horas}}",
  "lang_minutes": "hace {0,plural,one {1 minuto},other {# minutos}}",
  "lang_seconds": "hace {0,plural,one {1 segundo},other {# segundos}}",
  "lang_days": "{0,plural,zero {hoy},one {ayer},other {hace # días}}",
  "lang_star": "preferido",
  "lang_unstar": "ya no lo prefiero",
  "lang_mark": "marcar como leído",
  "lang_unmark": "marcar como no leído",
  "lang_load_img": "cargar Imágenes",
  "lang_open_window": "abrir",
  "lang_next": "siguiente",
  "lang_searchbutton": "buscar",
  "lang_searchremove": "ocultar búsqueda",
  "lang_refreshbutton": "actualizar fuentes",
  "lang_settingsbutton": "configuración",
  "lang_logoutbutton": "cerrar sesión",
  "lang_loginbutton": "iniciar sesión",
  "lang_sources": "Fuentes",
  "lang_source_add": "agregar fuente",
  "lang_source_opml": "o importar desde un archivo opml o desde Google Reader (Hasta el 15 de Julio de 2013)",
  "lang_source_export": "exportar fuentes",
  "lang_source_edit": "editar",
  "lang_source_filter": "Filtrar",
  "lang_source_delete": "borrar",
  "lang_source_warn": "realmente desea borrar esta fuente?",
  "lang_source_new": "nueva fuente sin guardar",
  "lang_source_title": "Titulo",
  "lang_source_autotitle_hint": "Dejar en blanco para obtener título",
  "lang_source_tags": "Etiquetas",
  "lang_source_pwd_placeholder": "no ha cambiado",
  "lang_source_comma": "separado por comas",
  "lang_source_select": "por favor seleccione una fuente",
  "lang_source_type": "Tipo",
  "lang_source_save": "Guardar",
  "lang_source_cancel": "cancelar",
  "lang_source_saved": "Guardado",
  "lang_source_last_post": "Última entrada vista",
  "lang_source_refresh": "Actualizar esta fuente",
  "lang_no_entries": "No se encontraron entradas",
  "lang_more": "más",
  "lang_login": "iniciar sesión",
  "lang_login_username": "usuario",
  "lang_login_password": "contraseña",
  "lang_login_invalid_credentials": "usuario/contraseña incorrectos",
  "lang_login_offline": "Almacenaje fuera de línea",
  "lang_no_title": "sin título",
  "lang_error": "ha ocurrido un error",
  "lang_streamerror": "Ningún elemento cargado, ¿recargar?",
  "lang_reload_list": "Recargar lista",
  "lang_sources_refreshed": "Las fuentes han sido refrescadas",
  "lang_app_update": "selfoss ha sido actualizado, recargue",
  "lang_app_reload": "Recargar",
  "lang_error_session_expired": "La sesión ha expirado",
  "lang_error_add_source": "Error añadiendo fuente:",
  "lang_error_delete_source": "Error borrando fuente:",
  "lang_error_load_tags": "Error cargando etiquetas:",
  "lang_error_unknown_tag": "Etiqueta desconocida:",
  "lang_error_unknown_source": "Id de fuente desconocido:",
  "lang_error_mark_items": "No se pudo marcar todos los elementos visibles:",
  "lang_error_mark_item": "No se pudo marcar/desmarcar elemento:",
  "lang_error_star_item": "No se pudo preferir/despreferir elemento:",
  "lang_error_logout": "No se pudo registrar:",
  "lang_error_loading": "Error cargando:",
  "lang_error_loading_stats": "No se pudo cargar estadísticas de navegación:",
  "lang_error_saving_color": "No se pudo cargar nuevo color:",
  "lang_error_refreshing_source": "No se pudo refrescar fuentes:",
  "lang_error_sync": "No se pudo sincronizar los últimos cambios del servidor:",
  "lang_error_offline_storage": "Error de almacenaje fuera de línea: {0}. Recargar puede ayudar. Desactivando uso fuera de línea por ahora.",
  "lang_error_invalid_subsection": "Subsección inválida:",
  "lang_search_label": "Buscar término",
  "lang_article_actions": "Acciones",
  "lang_error_configuration": "Imposible cargar configuración.",
  "lang_info_url_copied": "URL copiada al portapapeles.",
  "lang_share_native_label": "Compartir",
  "lang_share_diaspora_label": "Compartir en Diaspora",
  "lang_share_twitter_label": "Compartir en Twitter",
  "lang_share_facebook_label": "Compartir en Facebook",
  "lang_share_pocket_label": "Guardar en Pocket",
  "lang_share_wallabag_label": "Guardar en Wallabag",
  "lang_share_wordpress_label": "Compartir en WordPress",
  "lang_share_mail_label": "Compartir por e-mail",
  "lang_share_copy_label": "Copiar enlace al portapapeles",
  "lang_error_share_native_abort": "Imposible compartir ítem-no hay objetivos que compartir, o has cancelado.",
  "lang_error_share_native": "Imposible compartir ítem.",
  "lang_close_entry": "Cerrar",
  "lang_error_unwritable_logs": "Imposible escribir logs.",
  "lang_error_check_system_logs": "Un error ha ocurido, por favor comprueba tus logs de sistema.",
  "lang_error_check_log_file": "Un error ha ocurrido, por favor comprueba el fichero de log “{0}”.",
  "lang_offline_navigation_unavailable": "Cambiar etiquetas y fuentes no está disponible en el modo fuera de línea.",
  "lang_error_offline_storage_not_available": "Almacenaje fuera de línea no está disponible. Asegúrese que su navegador {0}soporta IndexedDB API{1} y, si está utilizando un navegador basado en Google Chrome, que no está usando selfoss en modo incógnito.",
  "lang_error_edit_source": "No se pudo guardar los cambios en las fuentes:",
  "lang_experimental": "Experimental",
  "lang_article_reading_time": "{0} min lectura",
  "lang_sources_loading": "Cargando fuentes…",
  "lang_source_warn_cancel_dirty": "¿Descartar los cambios no guardados?",
  "lang_error_unauthorized": "Por favor, {link_begin}firme{link_end} para continuar.",
  "lang_no_sources": "No hay fuentes configuradas.",
  "lang_source_menu": "Más acciones",
  "lang_source_browse": "Navegar",
  "lang_source_deleting": "Borrando fuente…",
  "lang_source_params_loading": "Cargando parámetros…",
  "lang_login_in_progress": "Intentando iniciar sesión…",
  "lang_source_saving": "Guardando cambios en la fuente…",
  "lang_source_go_to_settings": "Abrir en los ajustes",
  "lang_tag_change_color_button_title": "Cambiar el color",
  "lang_entries_loading": "Cargando las entradas…",
  "lang_login_error_generic": "No se ha podido iniciar la sesión: {errorMessage}",
  "lang_sources_leaving_unsaved_prompt": "¿Dejar la configuración con los cambios sin guardar?",
  "lang_share_mastodon_label": "Compartir en Mastodon"
}

import { useState, useCallback, useEffect } from 'react';

const defaultOptions = {};
/**
 *
 * useInput Hook
 *
 * Handles an input's value and onChange props internally to
 * make text input creation process easier
 *
 * @param {unknown} [initialValue] Initial value of the input
 * @param {Options} [options] Options object
 * @returns {InputHandler} Input handler with value and onChange
 * @see https://rooks.vercel.app/docs/useInput
 */
function useInput(initialValue = "", options = defaultOptions) {
    const [value, setValue] = useState(initialValue);
    const onChange = useCallback((event) => {
        const newValue = event.target.value;
        let shouldUpdate = true;
        if (typeof options.validate === "function") {
            shouldUpdate = options.validate(newValue, value);
        }
        if (shouldUpdate) {
            setValue(newValue);
        }
    }, [options, value]);
    // sync with default value
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);
    const handler = {
        onChange,
        value,
    };
    return handler;
}

export { useInput };

{
  "lang_markread": "Markera som läst",
  "lang_newest": "Nyast",
  "lang_unread": "Oläst",
  "lang_starred": "Stjärnmärkt",
  "lang_tags": "Taggar",
  "lang_alltags": "Alla taggar",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_hours": "för {0,plural,one {1 timme},other {# timmar}} sedan",
  "lang_minutes": "för {0,plural,one {1 minut},other {# minuter}} sedan",
  "lang_seconds": "för {0,plural,one {1 sekund},other {# sekunder}} sedan",
  "lang_days": "{0,plural,zero {idag},one {igår},other {för # dagar sedan}}",
  "lang_star": "stjärna",
  "lang_unstar": "utan stjärna",
  "lang_mark": "markera som läst",
  "lang_unmark": "markera som oläst",
  "lang_load_img": "bilder",
  "lang_open_window": "öppna",
  "lang_searchbutton": "sök",
  "lang_sources": "Källa",
  "lang_source_add": "Lägg till källa",
  "lang_source_opml": "eller importera från opml-fil eller Google Reader",
  "lang_source_edit": "redigera",
  "lang_source_delete": "ta bort",
  "lang_source_warn": "Vill du verkligen ta bort denna källa?",
  "lang_source_new": "ny källa",
  "lang_source_title": "Titel",
  "lang_source_tags": "Tagg",
  "lang_source_comma": "kommaseparerad",
  "lang_source_select": "välj källa",
  "lang_source_type": "Typ",
  "lang_source_save": "spara",
  "lang_source_cancel": "avbryt",
  "lang_source_saved": "sparad",
  "lang_no_entries": "hittade inga inlägg",
  "lang_more": "fler",
  "lang_login": "Logga in",
  "lang_login_username": "Användarnamn",
  "lang_login_password": "Lösenord",
  "lang_source_last_post": "Senaste artikel visad",
  "lang_filter": "Filter",
  "lang_online_count": "Objekt tillgängliga på servern",
  "lang_offline_count": "Objekt som är tillgängliga lokalt",
  "lang_next": "Nästa",
  "lang_searchremove": "Dölj sök",
  "lang_refreshbutton": "Uppdatera källor",
  "lang_settingsbutton": "Inställningar",
  "lang_logoutbutton": "Logga ut",
  "lang_loginbutton": "Logga in",
  "lang_source_export": "Exportera källor",
  "lang_source_filter": "Filter",
  "lang_source_autotitle_hint": "Lämna tomt för att hämta titel",
  "lang_source_pwd_placeholder": "Inte förändrad",
  "lang_source_refresh": "Uppdatera den här källan",
  "lang_login_invalid_credentials": "Fel användarnamn/lösenord",
  "lang_login_offline": "Offline-lagring",
  "lang_no_title": "Ingen titel",
  "lang_error": "Ett fel uppstod",
  "lang_streamerror": "Inga objekt laddade, ladda om?",
  "lang_reload_list": "Ladda om listan",
  "lang_sources_refreshed": "Källor uppdaterade",
  "lang_app_update": "selfoss har uppdaterats, ladda om igen",
  "lang_app_reload": "Ladda om",
  "lang_error_session_expired": "Sessionen har gått ut",
  "lang_error_add_source": "Kunde inte lägga till källa:",
  "lang_error_delete_source": "Kunde inte ta bort källan:",
  "lang_error_load_tags": "Kunde inte ladda taggar:",
  "lang_error_unknown_tag": "Okänd tagg:",
  "lang_error_unknown_source": "Okänt käll-ID:",
  "lang_error_mark_items": "Kan inte markera alla synliga objekt:",
  "lang_error_mark_item": "Kan inte markera/avmarkera objekt:",
  "lang_error_star_item": "Kan inte stjärna/avstjärna objekt:",
  "lang_error_logout": "Kunde inte logga in:",
  "lang_error_loading": "Laddningsfel:",
  "lang_error_loading_stats": "Kan inte ladda nav-statistik:",
  "lang_error_saving_color": "Det går inte att spara ny färg:",
  "lang_error_refreshing_source": "Kan inte uppdatera källor:",
  "lang_error_sync": "Kunde inte synkronisera senaste ändringarna från servern:",
  "lang_error_offline_storage": "Offline-lagringsfel: {0}. Omladdning kan hjälpa. Inaktiverar offline för tillfället.",
  "lang_error_invalid_subsection": "Ogiltigt underavsnitt:",
  "lang_search_label": "Sökterm",
  "lang_article_actions": "Åtgärder",
  "lang_error_configuration": "Det går inte att ladda konfiguration.",
  "lang_info_url_copied": "URL kopierad till urklipp.",
  "lang_share_native_label": "Dela",
  "lang_share_diaspora_label": "Dela på Diaspora",
  "lang_share_twitter_label": "Dela på Twitter",
  "lang_share_facebook_label": "Dela på Facebook",
  "lang_share_pocket_label": "Spara i Pocket",
  "lang_share_wallabag_label": "Spara i Wallabag",
  "lang_share_wordpress_label": "Dela på WordPress",
  "lang_share_mail_label": "Dela med e-post",
  "lang_share_copy_label": "Kopiera länk till urklipp",
  "lang_error_share_native_abort": "Det går inte att dela objektet - antingen finns det inga delningsmål eller så avbröt du det.",
  "lang_error_share_native": "Det går inte att dela objektet.",
  "lang_close_entry": "Stäng",
  "lang_experimental": "Experimentell",
  "lang_error_unwritable_logs": "Det gick inte att skriva loggar.",
  "lang_error_check_system_logs": "Ett fel uppstod. Kontrollera dina systemloggar.",
  "lang_error_check_log_file": "Ett fel uppstod. Kontrollera loggfilen “{0}”.",
  "lang_source_browse": "Bläddra",
  "lang_tag_change_color_button_title": "Ändra färg",
  "lang_source_warn_cancel_dirty": "Kasta bort osparade ändringar?",
  "lang_offline_navigation_unavailable": "Att byta taggar och källor är för närvarande inte tillgängligt i offlineläge.",
  "lang_sources_leaving_unsaved_prompt": "Lämna inställningar med osparade källändringar?",
  "lang_error_offline_storage_not_available": "Offlinelagring är inte tillgängligt. Se till att din webbläsare {0}stödjer IndexedDB API{1} och, om du använder en Google Chrome baserad webbläsare, att du inte kör selfoss i incognito läge.",
  "lang_error_edit_source": "Kunde inte spara källändringar:",
  "lang_source_menu": "Mer åtgärder",
  "lang_no_sources": "Inga källor konfigurerade.",
  "lang_source_deleting": "Raderar källa…",
  "lang_login_error_generic": "Kunde inte logga in: {errorMessage}",
  "lang_sources_loading": "Laddar källor…",
  "lang_source_params_loading": "Laddar parametrar…",
  "lang_source_saving": "Sparar källändringar…",
  "lang_source_go_to_settings": "Öppna i inställningar",
  "lang_entries_loading": "Läser in poster…",
  "lang_login_in_progress": "Försöker att logga in…",
  "lang_error_unauthorized": "Vänligen {link_begin}logga in{link_end} för att fortsätta.",
  "lang_article_reading_time": "{0}min läs",
  "lang_share_mastodon_label": "Dela på Mastodon"
}
